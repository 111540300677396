import * as React from "react"
import { Link } from "gatsby"

const Title = ({title, id, link, className='', buttonText, hideButton, subtitle}) => (
  <div id={id} className={'title p40 '+className}>
    <div className='flex bb1 pb20 m-pb20'>
      <div>
        <h2 className='h1 m0'>{title}</h2>
        {(subtitle ? <p className='m0 mt10'>{subtitle}</p>:'')}
      </div>
      {( link ?
        <div className={'mla aktiv '+(hideButton===true ? 'm-hide':'')}>
          <Link className='button' to={link}>{ buttonText ? buttonText : 'See All'}</Link>
        </div> :''
      )}
    </div>
  </div>
)

export default Title
